import { ApolloProvider } from '@apollo/client';
import { useMemo, useState, type FC, type PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { toast, useAuth } from '@volvo/vce-uikit';
import { GqlErrorDisplay } from '../../components/common/error/GqlErrorDisplay';
import { SiteConfigProvider } from '../../context/site-config/SiteConfigContext';
import { useQuerySiteMaterialFlowsWithMetricData } from '../../gql-types/generated-types-super-graph';
import { getApolloClient } from '../../gql/apollo-client';
import { isNotNull } from '../../helpers';
import mapI18n from '../../i18n';
import { Table } from './table/Table';

const ProductionDataContainer: FC<{ siteId: string }> = ({ siteId }) => {
  const [lastFetch, setLastFetch] = useState<number>();
  const response = useQuerySiteMaterialFlowsWithMetricData({
    variables: { siteId },
    pollInterval: Number(import.meta.env.VITE_POLL_INTERVAL),
    // onCompleted won't be called unless this is set...
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastFetch(Date.now()),
    onError: (error) => toast.error(<GqlErrorDisplay errors={error.graphQLErrors} />),
  });

  return (
    <Table
      loading={response.loading}
      materialFlowData={
        response.data?.siteMaps.site?.siteMaterialFlows.edges
          .map(({ node }) => node)
          .filter(isNotNull) || []
      }
      pointOfInterestData={response.data?.siteMaps.site?.pointsOfInterest || []}
      lastFetch={lastFetch}
    />
  );
};

export type Props = { siteId: string };
export const ProductionData: FC<PropsWithChildren<Props>> = ({ siteId }) => {
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  return (
    <I18nextProvider i18n={mapI18n}>
      <ApolloProvider client={client}>
        <SiteConfigProvider siteId={siteId}>
          <ProductionDataContainer siteId={siteId} />
        </SiteConfigProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
};

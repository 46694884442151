import { type FC } from 'react';
import { useSiteConfigContext } from '../../../../../context/site-config/SiteConfigContext';
import {
  MetricDefinitionType,
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../../../gql-types/generated-types-super-graph';
import { DataCell } from '../../data-cell/DataCell';
import { getMetric, getPoiName } from '../../helpers';
import { StatusIcon } from '../../status-icon/StatusIcon';
import { Row } from '../../styles';

type Props = {
  siteMaterialFlow: FragmentSiteMaterialFlowWithMetricData;
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
};

export const SensorRow: FC<Props> = ({ siteMaterialFlow, pointOfInterestData }) => {
  const { units } = useSiteConfigContext();

  const statusIndicator = siteMaterialFlow.siteMetricDefinitions.find(
    ({ type }) => type === MetricDefinitionType.StatusIndicator,
  );

  const massFlowRate = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.MassFlowRate],
    units,
  );

  const level = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.LevelVolume, MetricDefinitionType.LevelPercent],
    units,
  );

  return (
    <Row>
      <DataCell>{siteMaterialFlow.name} </DataCell>
      <DataCell>{getPoiName(siteMaterialFlow, pointOfInterestData)}</DataCell>
      <DataCell metricDefinition={statusIndicator} tooltipPlacement="right" tooltip>
        {statusIndicator ? (
          <StatusIcon status={statusIndicator?.currentSiteMetricData?.statusIndicator} />
        ) : null}
      </DataCell>
      <DataCell metricDefinition={massFlowRate.metricDefinition} tooltipPlacement="right" tooltip>
        {massFlowRate.value}
      </DataCell>
      <DataCell metricDefinition={level.metricDefinition} tooltipPlacement="left" tooltip>
        {level.value}
      </DataCell>
    </Row>
  );
};

import { autoFormatter, type Units as SystemUnits } from '@volvo/vce-package-units';
import {
  MaterialFlowType,
  MetricDefinitionType,
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
  type FragmentSiteMetricDefinitionData,
} from '../../../gql-types/generated-types-super-graph';
import type { Units } from '../../../helpers/convert/types';

export const compareMaterialFamily = (
  a?: FragmentSiteMaterialFlowWithMetricData[],
  b?: FragmentSiteMaterialFlowWithMetricData[],
): number => {
  if (!a || !a.length) return !b || !b.length ? 0 : -1;
  if (!b || !b.length) return 1;

  const aMaterialFamilyName = a[0].materialVariant.materialType.materialFamily.name.toLowerCase();
  const bMaterialFamilyName = b[0].materialVariant.materialType.materialFamily.name.toLowerCase();

  if (aMaterialFamilyName < bMaterialFamilyName) return -1;
  if (aMaterialFamilyName > bMaterialFamilyName) return 1;
  return 0;
};

export const getPreferredUnitForFlowRate = (units: Units): string => {
  return `${units.mass.unit}/h`;
};

export const getMetric = (
  siteMetricDefinitions: FragmentSiteMetricDefinitionData[],
  metricTypes: MetricDefinitionType[],
  units: Units,
): { metricDefinition?: FragmentSiteMetricDefinitionData; value: string } => {
  const metricDefinition = siteMetricDefinitions.find((t) => metricTypes.includes(t.type));

  if (!metricDefinition) return { metricDefinition: metricDefinition, value: '' };

  const metricData = metricDefinition.currentSiteMetricData;
  const metricType = metricDefinition.type;

  if (metricData) {
    switch (metricType) {
      case MetricDefinitionType.MassFlowRate:
        return {
          metricDefinition,
          value: autoFormatter(metricData.value, {
            unit: metricData.unit as SystemUnits,
            preferredUnit: getPreferredUnitForFlowRate(units) as SystemUnits,
          }),
        };

      case MetricDefinitionType.LevelVolume:
        return {
          metricDefinition,
          value: autoFormatter(metricData.value, {
            unit: metricData.unit as SystemUnits,
            preferredUnit: units.volume.unit as SystemUnits,
          }),
        };

      case MetricDefinitionType.LevelPercent:
        return {
          metricDefinition,
          value: `${autoFormatter(metricData.value, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}${metricData.unit}`,
        };
    }
  }

  return { metricDefinition, value: '-' };
};

export const getPoiName = (
  materialFlow: FragmentSiteMaterialFlowWithMetricData,
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[],
): string => {
  if (materialFlow.type === MaterialFlowType.PoiToZone) {
    return pointOfInterestData?.find((poi) => poi.id === materialFlow.fromId)?.name ?? '-';
  }

  if (materialFlow.type === MaterialFlowType.ZoneToPoi) {
    return pointOfInterestData?.find((poi) => poi.id === materialFlow.toId)?.name ?? '-';
  }
  return '-';
};

import { type FC } from 'react';
import { useSiteConfigContext } from '../../../../../context/site-config/SiteConfigContext';
import {
  MetricDefinitionType,
  type FragmentPointOfInterestForSiteMaterialFlow,
} from '../../../../../gql-types/generated-types-super-graph';
import { DataCell } from '../../data-cell/DataCell';
import { getMetric } from '../../helpers';
import { StatusIcon } from '../../status-icon/StatusIcon';
import { Row } from '../../styles';

type Props = {
  poi: FragmentPointOfInterestForSiteMaterialFlow;
};

export const PoiRow: FC<Props> = ({ poi }) => {
  const { units } = useSiteConfigContext();

  const statusIndicator = poi.siteMetricDefinitions.find(
    ({ type }) => type === MetricDefinitionType.StatusIndicator,
  );

  const massFlowRate = getMetric(
    poi.siteMetricDefinitions,
    [MetricDefinitionType.MassFlowRate],
    units,
  );

  const levelVolume = getMetric(
    poi.siteMetricDefinitions,
    [MetricDefinitionType.LevelVolume],
    units,
  );

  return (
    <Row>
      <DataCell>{poi.name}</DataCell>
      <DataCell>{poi.description}</DataCell>
      <DataCell metricDefinition={statusIndicator}>
        <StatusIcon status={poi.status} type="poi" />
      </DataCell>
      <DataCell metricDefinition={massFlowRate.metricDefinition} tooltipPlacement="right" tooltip>
        {massFlowRate.value}
      </DataCell>
      <DataCell metricDefinition={levelVolume.metricDefinition} tooltipPlacement="left" tooltip>
        {levelVolume.value}
      </DataCell>
    </Row>
  );
};

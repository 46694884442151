import { useRef, type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@volvo/vce-uikit';
import type { FragmentSiteMetricDefinitionData } from '../../../../gql-types/generated-types-super-graph';
import { useHoverDebounced } from '../../../../helpers/hooks/useHoverDebounced';
import { getTooltipContent } from './getTooltipContent';
import { StyledTooltip, TD } from './styles';

type Props = {
  metricDefinition?: FragmentSiteMetricDefinitionData;
  tooltip?: boolean;
  tooltipPlacement?: 'right' | 'left';
};

export const DataCell: FC<PropsWithChildren<Props>> = ({
  metricDefinition,
  tooltip,
  tooltipPlacement,
  children,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLTableCellElement>(null);

  const tooltipContent = getTooltipContent(metricDefinition);

  const isHovering = tooltip
    ? useHoverDebounced<HTMLTableCellElement | undefined>(ref.current || undefined, 500)
    : false;

  return (
    <TD ref={ref}>
      <StyledTooltip placement={tooltipPlacement}>
        <Tooltip open={isHovering} placement={tooltipPlacement === 'right' ? 'right-start' : 'top'}>
          {tooltipContent || t('production-data.no-data')}
        </Tooltip>
      </StyledTooltip>
      {children}
    </TD>
  );
};

import styled from '@emotion/styled';

export const TD = styled.td`
  font-size: 14px;
  line-height: 49px;
  vertical-align: middle;
  padding: 0 10px;
`;

export const StyledTooltip = styled.section<{ placement?: 'right' | 'left' }>`
  position: relative;
  > div {
    position: absolute;
    top: -20px;
    ${({ placement }) =>
      placement === 'right' ? 'left: 0' : placement === 'left' ? 'right: 0' : ''};
  }
`;
